export const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: "#2E83EB",
  width: "225px",
  height: "225px"
}

export const iconStyle = {
  display: 'flex',
  fontSize: '80px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px',
  color: '#74F19E'
}
